<template>
  <div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="col-sm-4 p-4 rounded-5 responsive bg-white shadow ">
      <div class="col-sm-12 text-center">
        <img src="../../assets/img/logo.png" alt="logo SYGALIN SAS" class="img-fluid"
             style="max-width: 60px; height: auto;margin-top: -3%;">
        <span class="col-sm-5" style="color: #0D5286; margin-left: 0; margin-top: 0; font-size: 200%"
        ><b>SYGALIN SAS</b></span>
        <div class="position-absolute">
          <img class="dame" src="../../assets/img/dame_en_veste_jaune.svg" style="
          margin-top: -70%;
          margin-left: -160%;
            top: 652px;
            left: 247px;
            width: 274px;
            height: 268px;
            padding-left: 65%"
               alt="La dame en veste jaune"/>
        </div>
      <br/>
      <br/>
      <div class="">
        <h2 class="mb-4"><b style="color: #0D5286; margin-top: -200%">Bienvenu(e) @{{ username }}</b></h2>
        <p class="MTNBrighterSans-Bold-p" style="margin-top: -26px; font-size: 12px  ">Veuillez insérer votre mot de passe!</p>
        </div>
      </div>
      <br/>
      <form @submit.prevent="submit">
        <div class="mb-2 row align-items-center d-flex justify-content-center">
          <div class="col-sm-9 ">
            <input  placeholder="Insérez votre mot de passe"  @input="typing"
                   :type="showPassword ? 'text' : 'password'"
                   class="form-control custom-option placeholder-bold rounded-pill " id="password"
                   v-model="password" required
                   style="background-color: #F6F8FA;" :disabled="isSubmitting"
            />
<!--            <div class="position-relative" style="margin-left: 82%; margin-top: -09%">-->
            <i @click="togglePasswordVisibility" :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
               style="cursor: pointer; margin: -08% 0 0 80%"></i>
            <!--            <n  @click="togglePasswordVisibility" >-->
<!--              <n v-if="showPassword" class="far fa-solid fa-eye"-->
<!--                 style="cursor: pointer; margin-left: 82%; margin-top: -8%"></n>-->
<!--              <n v-else class="far fa-solid fa-eye-slash"-->
<!--                 style="cursor: pointer; margin-left: 82%; margin-top: -8%"> </n>-->
<!--            </n>-->
<!--            </div>-->
            <div class="col-12">
              <br/>
              <span v-if="usernameError" style="font-size: .9375rem;" class="text-danger">Le mot de passe a au moins 9 caractères.</span>
<!--              <span v-if="isEmpty" class="text-danger text-start" style="margin-left: 8px" aria-required="true">Le champ mot de passe est requis.</span>-->
              <span v-if="netError" style="font-size: .9375rem;" class="text-danger">
                    Connexion à internet instable. Veuillez réessayer!
              </span>
              <span v-if="pwdError" class="text-danger" style="font-size: .9375rem;">
               <br/>
                Le mot de passe ou le login est incorrect
              </span>
            <div class="col-sm-7 text-start" style="margin-left: 8px">
              <br/>
              <router-link to="/recovery" class="router-link">
                <span class="mb-4" style="color: #6B982F; font-size:13px; font-family: 'MTNBrighterSans', sans-serif"
                ><b>Mot de passe oublié ?</b></span>
              </router-link>
            </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end" style="margin-top: 25%">
          <button type="submit" class="my-button" :disabled="isSubmitting">
            <span v-if="!isSubmitting" id="button-text">Suivant</span>
            <div v-else class="spinner" id="spinner" ></div>
          </button>
        </div>
      </form>
    </div>
    <div class="img-fluid dame col-sm-5 d-none d-sm-none d-md-block d-lg-block" style="margin-left: 10%;">
      <img class="img-fluid" src="../../assets/img/securité%20dame.svg"
           style="max-width: 100%; height: auto; margin-top: 13%;" alt="image du globe">
    </div>
  </div>
</template>


<script>

import {baseUrl} from "@/store/baseUrl";
import { useRoute } from 'vue-router';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'signon',
  components: {
  },
  data() {
    return {
      username: localStorage.getItem('username'),
      password: '',
      pwdError: false,
      usernameError: false,
      passwordErrorUp: true,
      passwordErrorMin: true,
      passwordError: true,
      isEmpty: false,
      netError:false,
      networkError: false,
      showPassword: false,
      disactivate: false,
      isSubmitting:false
    };
  },
  beforeMount() {
    if (this.username === ''){
      this.$route.push('/signin')
    }
  },
  computed: {
    currentDate() {
      const now = new Date();
      const formattedDate = now.toLocaleDateString('fr-FR');
      return formattedDate;
    },
  },
  created() {
    // const password = localStorage.getItem('password')
    // if (password){
    // localStorage.removeItem('password')
    // }
  },
  methods: {
    typing(){
      this.pwdError = false;
      this.isEmpty = false;
      this.usernameError = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      const passwordInput = document.getElementById('password');
      passwordInput.type = this.showPassword ? 'text' : 'password';
    },
    async submit() {
      if (this.password === '') {
        this.isEmpty = true;
      } else {
        const data = {
          username: this.username,
          password: this.password
        };
        this.isSubmitting = true;
        // console.log(data)
        try {
          this.netError = false
          const route = useRoute()
          console.log("queries: ", this.$route.query)
          const queryStrings = new URLSearchParams(this.$route.query).toString()
          // if (queryStrings === ''){
          //  const queryStrings = "https://main-dev.d11i2lnfx245ly.amplifyapp.com/marketplace"
          //   console.log(queryStrings)
          //   const controller = new AbortController();
          //   const signal = controller.signal;
          //   const timeout  = setTimeout(() => {
          //     controller.abort();
          //     this.isSubmitting = false;
          //     this.netError = true
          //   }, 30000);
          //   const response = await fetch(`${baseUrl}/auth/v1/challenge/password/?${queryStrings}` ,
          //       {
          //         signal,
          //         method: 'post',
          //         credentials: 'include',
          //         headers: {
          //           // 'Authorization': 'tokken',
          //           'Content-Type': 'application/json'
          //         },
          //         body: JSON.stringify(data)
          //         // signal: signal
          //       });
          //   this.respond = await response.json();
          //   if (this.respond !== null){
          //     clearTimeout(timeout);
          //   }
          //   console.log('Réponse: ', this.respond);
          //   if (this.respond.status !== 202){
          //     if (this.respond.code === "AUTH_NOT_FOUND"){
          //       this.isSubmitting = false;
          //       localStorage = clear();           }
          //     if (this.respond.code === "AUTH_NOT_ACCEPTABLE"){
          //       this.isSubmitting = false;
          //       this.disactivate = true;
          //       console.log('Compte utilisateur non actif');
          //     }
          //     if (this.respond.code === "AUTH_NOT_AUTHORIZED"){
          //       this.isSubmitting = false;
          //       this.pwdError = true
          //     }
          //   }
          //   else
          //   {
          //     localStorage.clear()
          //     this.isSubmitting = false;
          //     window.location.href = this.respond.data.redirectTo;
          //   }
          // }
          const controller = new AbortController();
          const signal = controller.signal;
          const timeout  = setTimeout(() => {
            controller.abort();
            this.isSubmitting = false;
            this.netError = true
          }, 30000);
          const response = await fetch(`${baseUrl}/auth/v1/challenge/password/?${queryStrings}` ,
              {
                signal,
                method: 'post',
                credentials: 'include',
                headers: {
                  // 'Authorization': 'tokken',
                  'Content-Type': 'application/json',
                  "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(data)
                // signal: signal
              });
          this.respond = await response.json();
          if (this.respond !== null){
            clearTimeout(timeout);
          }
          console.log('Réponse: ', this.respond);
          if (this.respond.status !== 202){
            if (this.respond.code === "AUTH_NOT_FOUND"){
              this.isSubmitting = false;
              localStorage = clear();           }
            if (this.respond.code === "AUTH_NOT_ACCEPTABLE"){
              this.isSubmitting = false;
              this.disactivate = true;
              console.log('Compte utilisateur non actif');
            }
           if (this.respond.code === "AUTH_NOT_AUTHORIZED"){
             this.isSubmitting = false;
            this.pwdError = true
           }
          }
          else
          {
            localStorage.clear()
            this.isSubmitting = false;
            window.location.href = this.respond.data.redirectTo;
          }
        } catch (e) {
          this.netError = true;
          this.isSubmitting = false;
        }

      }
    }
  },
};
</script>

<style>
.password-input {
  position: relative;
}

.password-input input.show-password {
  -webkit-text-security: none;
  text-security: none;
}

.password-input .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.MTNBrighterSans-Bold-p {
  font-family: Raleway,regular;
  font-size: 12px;
  color: #000000!important;
  opacity: 10;
  margin-top: -30px;
}
.MTNBrighterSans-Bold-Sygalin {
  font-family: MTNBrighterSans, sans-serif;
  font-size: 11px;
  color: #0E5386;
}

::placeholder {
  color: #0D5286;
}

input::placeholder {
  color: #0D5286;
}

.MTNBrighterSans-Bold-light {
  font-family: MTNBrighterSans, sans-serif;
  font-size: .9375rem;
  color: #B1B7C4;
}
.placeholder-bold::placeholder {
  font-weight: bold;
  color: #0D5286;
}
.custom-option{
  color: #0D5286;
}

.router-link {
  text-decoration: none;
}
</style>
