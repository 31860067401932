<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <input
            placeholder="Créer votre mot de passe"
            id="password"
            v-model="password"
            @input="validatePassword"
            :type="showPassword1 ? 'text' : 'password'"
            class="form-control custom-option placeholder-bold rounded-pill"
            :class="{'is-invalid': passwordErrors.length}"
            required :disabled="isSubmitting"
        />
<!--        <span >-->
          <i @click="togglePasswordVisibility1" :class="showPassword1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
             style="cursor: pointer; margin: -08% 0 0 80%"></i>
<!--        </span>-->
        <p class="">
           <span v-if="netError" style="font-size: .9375rem;" class="text-danger">
                    Connexion à internet instable. Veuillez réessayer!
              </span>
        </p>
        <div class="invalid-feedback" v-if="passwordErrors.length">
          <div v-for="error in passwordErrors" :key="error" style="font-size: .9375rem;">{{ error }}</div>
        </div>
      </div>

      <div class="form-group">
        <input
            placeholder="Confirmer"
            id="confirmPassword"
            v-model="confirmPassword"
            @input="validateConfirmPassword"
            :type="showPassword2 ? 'text' : 'password'"
            class="form-control custom-option placeholder-bold rounded-pill"
            required :disabled="isSubmitting"
        />
          <i @click="togglePasswordVisibility2" :class="showPassword2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
             style="cursor: pointer; margin: -08% 0 0 80%"></i>
        <br/>
        <span v-if="isConform" class="text-danger" style="font-size: .9375rem;"> Les mots de passes sont différents</span>
        <span v-if="hacked" class="text-danger" style="font-size: .9375rem;">
          Améliorez votre mot de passe pour une meilleure sécurité.
        </span>
        <span v-if="sessionError === true" class="text-danger" style="font-size: .9375rem;"
        >Vous ne pouvez pas poursuivre. Veuillez recommencer!</span>
        <div class="invalid-feedback" v-if="confirmPasswordError">
          {{ confirmPasswordError }}
        </div>
<!--      </div>-->
      <div class="d-flex justify-content-end">
        <button type="submit" class="my-button" style="margin-top: 20%" :disabled="isSubmitting">
          <span v-if="!isSubmitting" > Valider </span>
          <div v-else class="spinner" id="spinner" ></div>
        </button>
      </div>
      </div>
    </form>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';
import {baseUrl} from "@/store/baseUrl";
import {passwordSize} from "@/passwordParams/passwordConfig";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'passwordmodal',
  data() {
    return {
      isSubmitting:false,
      pwdError:'',
      sessionError:false,
      password: '',
      netError:'',
      confirmPassword: '',
      showPassword1: false,
      showPassword2: false,
      passwordErrors: [],
      hacked: false,
      isConform: false,
      confirmPasswordError: '',
      remainingTime: localStorage.getItem('timeout'),
      timer: null,
    };
  },
  mounted() {
  },
  methods: {

    typing(){
      if(this.password !== this.confirmPassword){
        this.hacked = false
        this.isConform = false
      }
    },
    togglePasswordVisibility1() {
      this.showPassword1 = !this.showPassword1;
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
    },
    validatePassword() {
      // const min = 8
      this.passwordErrors = [];
      let passwordMinSize = passwordSize;
      if (this.password.length < passwordMinSize) {
        this.passwordErrors.push('Votre mot de passe a moins de '+ passwordMinSize +' caractères.');
      }
      this.validateConfirmPassword();
    },
    validateConfirmPassword() {
      this.confirmPasswordError = '';
      if (this.password !== this.confirmPassword) {
        this.isConform = true
        this.confirmPasswordError = 'Les mots de passe doivent être identiques!';
      }else {
        this.isConform = false
      }
    },
    async submitForm() {
      this.validatePassword();
      this.validateConfirmPassword();
      if (this.password === this.confirmPassword){
      try{
        this.netError = false
      const data = {
        session_id: localStorage.getItem('session_id'),
        username: localStorage.getItem('username'),
        first_name: localStorage.getItem('first_name'),
        last_name: localStorage.getItem('last_name'),
        birth_date: localStorage.getItem('birth_date'),
        iso2_country_code: localStorage.getItem('iso2_country_code'),
        gender: localStorage.getItem('gender'),
        email: localStorage.getItem('email'),
        phone_number: localStorage.getItem('phone_number'),
        phone_number_code: localStorage.getItem('phone_number_code'),
        password: this.password
      }
      this.isSubmitting = true;
        const controller = new AbortController();
        const signal = controller.signal;
        const timeout  = setTimeout(() => {
          controller.abort();
          this.isSubmitting = false;
          this.netError = true
        }, 30000);
      const response = await fetch(`${baseUrl}/account/v1/create/` ,
          {
            signal,
            method: 'post',
            headers: {
              // 'Authorization': 'tokken',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(data)
            // signal: signal
          });

      // console.log(data)
        if (this.respond !== null){
          clearTimeout(timeout);
        }
      this.respond = await response.json();
      console.log(this.respond)
      if (this.respond.status === 200){
        console.log("Compte créer avec succes")
        localStorage.removeItem('session_id');
        localStorage.removeItem('username');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('birth_date');
        localStorage.removeItem('iso2_country_code');
        localStorage.removeItem('gender');
        localStorage.removeItem('email');
        localStorage.removeItem('phone_number');
        localStorage.removeItem('phone_number_code');
        this.$router.push('/success_create');
        this.isSubmitting = false;
      }else if (this.respond.code === 'PASSWORD_COMPROMISED'){
        this.hacked = true
        console.log("Améliorez votre mot de passe pour une meilleure sécurité.")
        this.isSubmitting = false;
      }else if (this.respond.status === 401){
        localStorage.removeItem('session_id');
        localStorage.removeItem('username');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('birth_date');
        localStorage.removeItem('iso2_country_code');
        localStorage.removeItem('gender');
        localStorage.removeItem('email');
        localStorage.removeItem('phone_number');
        localStorage.removeItem('phone_number_code');
          this.$router.push('/error');
        }
      this.isSubmitting = false;
      console.log("La création de compte a rencontré une erreur :", this.respond);
    }catch (e) {
        this.netError = true;
        this.isSubmitting = false;
    }
    }
  }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
}

</style>
